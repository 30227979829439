












































































import Vue from 'vue';
import BaseVue from './Base.vue';
import LoadingVue from '@/components/Loading.vue';
import ModalVue from '@/components/Modal.vue';
import {buildAdminStats, fetchAdminStats} from '@/services/parseQueries';
import moment from 'moment';

export default Vue.extend({
    name: 'Dashboard',
    extends: BaseVue,
    components: {LoadingVue, ModalVue},
    data() {
        return {
            loading: false,
            chartsLoading: false,
            errorMessage: '',
            totalTeams: null,
            totalMembers: null,
            totalUsers: null,
            statsUpdatedAt: '',
            stats: [],
            showModal: false,
            modalLoading: false,
            buildStatsDate: '',
            invalidDate: false,
            showResult: false,
            actionError: false,
            buildStatsResult: ''
        };
    },

    async mounted() {
        this.loading = true;
        try {
            const [stats, totalTeams, totalMembers, totalUsers] = await fetchAdminStats();
            this.totalTeams = totalTeams;
            this.totalMembers = totalMembers;
            this.totalUsers = totalUsers;
            this.statsUpdatedAt = stats.length ? moment(stats[stats.length - 1].updatedAt).format() : '';
            this.stats = stats;

            if (google && google.visualization && google.visualization.LineChart) {
                this.renderCharts();
            } else {
                this.chartsLoading = true;
                this.$root.$on('google:chartsLoaded', this.renderCharts);
            }
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.errorMessage = (error.response && error.response.data && error.response.data.error.message) ||
                error.message;
        }
    },

    methods: {
        showRebuildStatsDialog() {
            this.buildStatsDate = moment().format('YYYY-MM-DD');
            this.actionError = false;
            this.showModal = true;
        },

        async rebuildStats() {
            if (!this.validateDate()) {
                return;
            }

            try {
                this.modalLoading = true;
                await buildAdminStats(this.buildStatsDate);
                this.buildStatsResult = 'Successfully built admin stats';
                this.showModal = false;
            } catch (error) {
                this.actionError = true;
                this.buildStatsResult = error.response.data.error.message;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.showResult = true;
            }
        },

        validateDate() {
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.buildStatsDate)) {
                this.invalidDate = false;
                return true;
            } else {
                this.invalidDate = true;
                return false;
            }
        },

        renderCharts() {
            this.$root.$off('google:chartsLoaded');
            this.renderCreatedTeamsChart();
            this.renderJibblesChart();
            this.renderActiveTeamsChart();
            this.renderActiveJibblersChart();
            this.chartsLoading = false;
        },

        renderActiveTeamsChart() {
            const data = new google.visualization.DataTable();
            data.addColumn('string', 'Time');
            data.addColumn('number', 'Active Teams');
            this.stats.forEach((stat: any) => {
                const date = moment(stat.date.iso).format('YYYY-MM-DD');
                data.addRow([date, stat.activeTeams]);
            });
            this.renderChart(this.$el.getElementsByClassName('js-active-teams-chart')[0], data);
        },

        renderActiveJibblersChart() {
            const data = new google.visualization.DataTable();
            data.addColumn('string', 'Time');
            data.addColumn('number', 'Active Jibblers');
            this.stats.forEach((stat: any) => {
                const date = moment(stat.date.iso).format('YYYY-MM-DD');
                data.addRow([date, stat.activeJibblers]);
            });
            this.renderChart(this.$el.getElementsByClassName('js-active-jibblers-chart')[0], data);
        },

        renderCreatedTeamsChart() {
            const apps = this.getAppsFromChartData('teams');
            const data = new google.visualization.DataTable();
            data.addColumn('string', 'Time');
            if (apps.length) {
                apps.forEach(app => {
                    data.addColumn('number', app);
                });
                this.stats.forEach((stat: any) => {
                    const row = new Array(apps.length + 1).fill(0);
                    row[0] = moment(stat.date.iso).format('YYYY-MM-DD');
                    const teams = stat.teams;
                    Object.keys(teams).forEach(key => {
                        const appIndex = apps.indexOf(key);
                        row[appIndex + 1] += teams[apps[appIndex]];
                    });
                    data.addRow(row);
                });
            } else {
                data.addColumn('number', 'Created Teams');
                this.stats.forEach((entry, index) => {
                    data.addRow([index + 1, 0]);
                });
            }
            this.renderChart(this.$el.getElementsByClassName('js-created-teams-chart')[0], data);
        },

        renderJibblesChart() {
            const apps = this.getAppsFromChartData('jibbles');
            const data = new google.visualization.DataTable();
            data.addColumn('string', 'Time');
            if (apps.length) {
                apps.forEach(app => {
                    data.addColumn('number', app);
                });
                this.stats.forEach((stat: any) => {
                    const row = new Array(apps.length + 1).fill(0);
                    row[0] = moment(stat.date.iso).format('YYYY-MM-DD');
                    const jibbles = stat.jibbles;
                    Object.keys(jibbles).forEach(key => {
                        const appIndex = apps.indexOf(key);
                        row[appIndex + 1] += jibbles[apps[appIndex]];
                    });
                    data.addRow(row);
                });
            } else {
                data.addColumn('number', 'Jibbles');
                this.stats.forEach((entry, index) => {
                    data.addRow([index + 1, 0]);
                });
            }
            this.renderChart(this.$el.getElementsByClassName('js-jibbles-chart')[0], data);
        },

        getAppsFromChartData(statsProp: string) {
            const apps: string[] = [];
            this.stats.forEach((entry: any) => {
                Object.keys(entry[statsProp]).forEach((key: string) => {
                    if (apps.indexOf(key) === -1) {
                        apps.push(key);
                    }
                });
            });
            return apps;
        },

        renderChart(container: Element, data: google.visualization.DataTable) {
            const options: google.visualization.LineChartOptions = {
                chartArea: {left: 30, bottom: 100, top: 20, right: 20},
                hAxis: {
                    title: 'Time',
                    gridlines: {
                        count: 30,
                        color: 'none'
                    },
                    viewWindow: {min: 1, max: 30}
                },
                vAxis: {
                    viewWindow: {min: 0},
                    format: '0'
                },
                legend: {
                    position: 'bottom'
                }
            };
            const chart = new google.visualization.LineChart(container);
            chart.draw(data, options);
        }
    }
});
